import * as Msdyn365 from '@msdyn365-commerce/core';

/**
 * Sharestate Input Action
 */
export class SharestateInput implements Msdyn365.IActionInput {
    constructor() {}
    public getCacheKey = () => `DEFAULT`;
    public getCacheObjectType = () => 'ISharestateData';
    public dataCacheType = (): Msdyn365.CacheType => 'application';
}

/**
 * Data model to capture the response of the action
 */
export interface ISharestateData {
    isModalOpen: boolean;
    email: string;
}

/**
 * Input for action call
 */
const createInput = (args: Msdyn365.ICreateActionContext): Msdyn365.IActionInput => {
    return new SharestateInput();
};

async function action(input: SharestateInput, ctx: Msdyn365.IActionContext): Promise<ISharestateData> {
    return { isModalOpen: false, email: '' };
}

export default Msdyn365.createObservableDataAction({
    action: <Msdyn365.IAction<ISharestateData>>action,
    id: 'NLSharestate',
    input: createInput
});
